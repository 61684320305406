/* InstagramLink.css */
.instagram-link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff; /* or use Instagram's brand color */
    text-decoration: none;
    font-size: 1.5em; /* Adjust the size as needed */
  }
  
  .instagram-link:hover {
    color: #E1306C; /* Change color on hover */
  }
  
.about-me {
    padding: 50px 0;
  }
  
  .about-me-container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1100px;
    margin: 0 auto;
    gap: 20px; /* Spacing between image and text */
  }
  
  .about-me-image {
    flex: 1; /* Takes up 1/2 of the flex container */
    max-width: 50%; /* Image container should not grow beyond half the parent width */
  }
  
  .about-me-image img {
    width: 100%; /* Make image fill the container */
    height: auto; /* Keep image aspect ratio */
    border-radius: 50%; /* Optional: rounds the corners of the image */
  }
  
  .about-me-info {
    flex: 1; /* Takes up the remaining 1/2 of the flex container */
    padding: 0 20px;
  }
  
  .about-me-info h2 {
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 20px;
  }
  
  .about-me-info p {
    font-size: 1rem;
    line-height: 1.6;
    color: #fff;
    margin-bottom: 20px;
  }
  
  /* For smaller screens, stack the layout */
  @media (max-width: 768px) {
    .about-me-container {
      flex-direction: column;
      text-align: center;
    }
  
    .about-me-image {
      max-width: 80%;
      margin: 0 auto; /* Center the image block on small screens */
    }
  
    .about-me-info {
      padding: 0;
    }
  }
  