.sec_sp {
    margin-bottom: calc(3rem + 5.128vw)
}

.ab_block_desc{
    height: auto;
width: 940px;
padding: 5px 5px 5px 5px;
}

.ab_block {
height: auto;
width: 940px;
padding: 5px 5px 5px 5px;
margin: 0px auto 0px auto;
display: block;
background: #fff;
color: #e9e9e9;
}

@-webkit-keyframes animate-positive {
    0% {
        width: 0%;
    }
}

@keyframes animate-positive {
    0% {
        width: 0%;
    }
}

.section-title {
    font-size: 45px;
}

.service__title {
    padding: 8px 0;
    border-bottom: solid 2px var(--secondary-color);
}

.service-section .service-category-title {
    padding-bottom: 4px;
}


/*! CSS Used keyframes */

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}
