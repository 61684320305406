.po_items_ho {
    column-gap: 2rem;
    -moz-column-gap: 2rem;
    -webkit-column-gap: 2rem;
    column-count: 3;
}
/* .po_items_ho {
    column-width: 15em;
    -moz-column-width: 15em;
    -webkit-column-width: 15em;
    column-gap: 4rem;
    -moz-column-gap: 4rem;
    -webkit-column-gap: 2rem;
} */

.po_item {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 0.5rem auto;
    position: relative;
    background: var(--secondary-color);
    padding: 6px;
    border: 1px solid var(--secondary-color);
    transition: 0.3s ease;
    font-size: 0;
    min-height: 170px;
}

.po_item img {
    max-width: 100%;
}

.po_item .content {
    position: absolute;
    height: 0;
    width: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--overlay-color);
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    transition: 0.3s ease-in-out;
    opacity: 0;
    border: 1px solid black;
}

.po_item .content {
    opacity: 0;
    transition-delay: 1s;
    transition: 0.3s ease;
    font-size: 20px;
}

.po_item:hover {
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.25);
}

.po_item:hover .content {
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    opacity: 1;
}

.po_item:hover .content {
    opacity: 1;
    color: var(--secondary-color);
}

.po_item .content a {
    background: var(--bg-color);
    border: solid 1px var(--text-color);
    padding: 4px 8px;
    text-align: center;
    font-size: 1rem;
}

.po_item .content a:hover {
    text-decoration: none;
}

@media screen and (max-width:1090px) and (min-width: 980px){
 
.container, .container-lg, .container-md, .container-sm {
    max-width: 870px !important;
}

}

@media screen and ( max-width: 968px) and (min-width:762px) {
    .po_items_ho {
        column-count: 2 !important;
    }
}
@media screen and ( max-width: 762px) {
    .po_items_ho {
        column-count: 1 !important;
    }
}

.swiper {
    overflow: visible !important;
}

.swiper-slide {
    width: 300% !important;
    padding-right: 30px !important;
}
.copyright {
    font-size: 14px !important;
}
.slider_height{
    height: 100vh;
}
.slider_height img{
    height: 100vh;
    float: right;
    
}
.div_slider_height{
    margin-top: 0px !important;
}
.margin_custom{
    margin-top: -100px;
}
.invert{
    filter: invert(1);
}