/*Anouncement bar*/
/* page {
    top: 30.5px;
} */
.announcement-bar {
    display: none !important;
}
/*Anouncement bar*/
/*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/

.vertical-nav {
    min-width: 20rem;
    width: 20rem;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    transition: all 0.4s;
    z-index: 10;
}

.page-content {
    width: calc(100% - 20rem);
    margin-left: 20rem;
    transition: all 0.4s;
}

/* for toggle behavior */



#content.active {
    width: 100%;
    margin: 0;
}

.bg_musicVideo {

    background-size: cover;
    padding: 0 2em;
}

@media (max-width: 768px) {


    #content {
        width: 100%;
        margin: 0;
    }

    #content.active {
        margin-left: 17rem;
        width: calc(100% - 17rem);
    }
}

.gallery_adjust {
    width: 80%;
    margin: auto;
    margin-top: 10px;
    border: 1px solid rgba(0, 0, 0, .1);

}

.gallery_adjust p {
    text-align: center;
    padding: 4px;
    font-family: monospace;
    background: #222;
    color: #efefef;
}

.gallery_img {
    transform: rotate(0);
    transition: all ease 0.4s;
}

.gallery_img:hover {
    transition: all ease 0.4s !important;
}

.color_title {
    color: rgb(207, 167, 140);
}

h2 {
    font-family: var(--secondary-font-family);
    font-weight: 600;
    font-size: 25px;
}

h4 {
    font-family: var(--secondary-font-family);
    font-weight: 400;
    font-size: 20px;
}

p {
    font-family: var(--secondary-font-family);
    font-size: 20px;
}

.vertical-nav h4 {
    font-size: 4.07em;
    font-family: Poppins;
    line-height: 1.05em;
    font-weight: 400;
}

.nav-item {
    font-size: 2em;
}

.nav-item:hover {
    cursor: pointer;
    opacity: 0.5 !Important;
    transform: scale(1.1) !important;
    margin-left: 0.5em !important;
    transition: all 0.4s;
}

.nav-item.active {
    cursor: pointer;
    opacity: 0.5 !Important;
    transform: scale(1.1) !important;
    margin-left: 0.5em !important;
    transition: all 0.4s;
}

.nav-item {
    opacity: 1;
    transform: scale(1);
    margin-left: 0em;
    transition: all 0.4s;
}

.vertical-nav h4:hover {
    cursor: pointer;
}

.bg_musicVideo:hover img {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    transition: all 0.4s;
    cursor: pointer;
}

.bg_musicVideo img {
    transition: all 0.4s;
}

.c_pointer {
    cursor: pointer;
}

.contentMinH {
    min-height: 90vh;
}

.strong_g {
    font-weight: bolder;
}

.bege {
    color: #cfa78c;
}

.bg_bege {
    background-color: #cfa78c;
}

.div_commercials:last-child .imgs_gallery {
    margin-bottom: -4em;
    padding-bottom: 5em;
}

/* apagar apos mais conteudo _> ALERTA */
.div_correction {
    min-height: 60vh;
}

@media screen and (max-width: 720px) {

    .page-content {
        width: 100% !important;
        margin-left: 0rem !important;
        transition: all 0.4s;
    }

}



div.sidebar-active {
    left: 21em;
    z-index: 11;
}

#idBtnSidebar {
    transition: all 0.4s;
}

#idInfo {
    transition: all 0.4s;
    left: 4em;
    background-color: #fff;
    width: max-content;
    top: 0.3em;
    border-radius: 5%;
    opacity: 80%;
}

@media screen and (min-width:768px) {

    #idBtnSidebar,
    #idInfo {
        display: none;
    }


}

@media screen and (max-width: 768px) {

    #sidebar.active {
        left: 0rem;
    }

    #sidebar {
        left: -20rem;
    }

    .page-content {
        width: 100% !important;
        margin-left: 0rem !important;
    }

    .bg_musicVideo {
        padding: 0 4em !important;
    }

    /* .page-content.active{
        width: calc(100% - 20rem) !important;
        margin-left:20em !important;
    } */

}

.title_image_mobile {
    display: none;
}

@media screen and (max-width:600px) {
    .width_mobile {
        width: 100% !important;
    }

    .mobile_title_padding {
        padding-bottom: 0em !important;
    }

    .title_image_mobile {
        display: block !important;
        width: 50% !important;
        padding: 1em !important;
        height: 50% !important;
    }

    .title_image_desk {
        display: none !important;
    }

    .center_text_mobile {
        text-align: center !important;
    }

    .profile_mobile_img {
        width: 80% !important;
        height: auto !important;
    }
    
    .mobile_left_about{
        display: none;
    }

    .table_mobile {
        padding-left: 2em !important;
        padding-right: 2em !important;
        overflow: scroll;
    }

    .index_menu_pb {
        padding-bottom: 1em !important;
    }

}

.video_third_width {
    width: 33.33% !important;
}

.video-react.video-react-fluid,
.video-react.video-react-16-9,
.video-react.video-react-4-3 {
    min-height: 565px;
}

.selected_clients {
    background: white;
}

.text-center_webkit {
    text-align: center;
    text-align: -webkit-center;
}

.text_var {
    color: var(--text-color);
}

.goback {
    left: 20px;
    top: 80px;
}

.margin-bottom-3 {
    margin-bottom: -0.75rem;
}

.slick-slide:not(.slick-current) img {
    filter: blur(10px);
    transition: filter ease 0.4s;
}

.slick-slide .slick-current img {
    filter: blur(0px);
    transition: filter ease 0.4s;
}

@media screen and (max-width:991px) {
    .slider_height img {
        height: 50vh !important;
    }

    .slick-slider.slider_height {
        height: 65vh;
        min-height: 65vh;
        margin-bottom: 30px;
    }

    .goback {

        bottom: 0px !important;
    }

    .mobile_hr {
        display: block !important;
    }

    .About-header.container {
        padding-bottom: 60px !important;
    }

    .video-react.video-react-fluid,
    .video-react.video-react-16-9,
    .video-react.video-react-4-3 {
        min-height: 200px !important;
    }
}

@media screen and (max-width:1550px) {
    .goback {
        left: 20px;
        top: unset !important;
        bottom: 0px;
        margin: 10px 10px;
    }

    .About-header.container {
        padding-bottom: 100px;
    }

}

@media screen and (max-width: 1514px) and (min-width:1017px){
    .slick-slide {
        height: 50vh !important;
    }
}

.ab_block {
    max-width: 100%;
}

.mobile_hr {
    display: none;
}

@media  screen and (max-width: 900px) and (min-width: 792px){
    .container {
        max-width: 670px !important;
    }
}
.the_menu .menu_item>button {
    color: var(--text-color-2);
    line-height: 1;
    font-size: 2rem;
    display: inline-block;
    position: relative;
    transition: color 250ms cubic-bezier(0, 0, 0.58, 1) 0s;
    padding: 4px 0px;
    font-family: Marcellus;
}

@media (min-width: 768px){
    .the_menu .menu_item>button {
        font-size: 4.8vw;
    }
}

.menu_item>button:hover {
    text-decoration: underline;
}

/* .table_mobile::-webkit-scrollbar-thumb {
    background: white;
    color: white;
    width: 200px;
    height: 10px; 
  } */

  .navbar-brand img.light{
    filter: invert(0);
  }

  .imgs_gallery{
    background: #fff !important;
  }

