/* PriceTable.css */
.price-table-container {
    overflow-x: auto;
    width: 100%;
    margin: 0 auto;
    padding: 5%;
  }
  
  .price-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto; /* This ensures that column widths are set by their contents */
  }
  
  .price-table thead th {
    background-color: #f4f4f4;
    color: #333;
    font-weight: bold;
    padding: 15px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .price-table tbody td {
    padding: 15px;
    border: 1px solid #ddd;
    text-align: center;
  }
  
  .price-table tbody tr:nth-child(even) {
    background-color: #000;
  }
  
  @media screen and (max-width: 768px) {
    .price-table {
      font-size: 0.8em; /* Adjust size for small screens */
    }
  }
  