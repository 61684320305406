/* PriceEstimator.css */
form {
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #fff;
    color: #000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  form div {
    margin-bottom: 10px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  select,
  input[type="checkbox"] {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #000;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  input[type="checkbox"] {
    width: auto;
    margin-top: 0;
  }
  
  button[type="submit"] {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #0056b3;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button[type="submit"]:hover {
    background-color: #003d82;
  }
  
  /* p {
    padding: 10px;
    border-radius: 4px;
    background-color: #f4f4f4; 
    color: #333;
    border: 1px solid #ddd;
    text-align: center;
  } */
  
  /* Small screens adjustment */
  @media screen and (max-width: 600px) {
    form {
      width: calc(100% - 40px); /* Full width minus padding */
    }
  
    select,
    input[type="checkbox"] + label {
      display: block; /* Make sure label goes below checkbox on small screens */
    }
  
    button[type="submit"] {
      font-size: 0.9em;
    }
  }
  