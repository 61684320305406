.package-card-div {
    border: 1px solid #ddd;
    padding: 1px;
    /* border-radius: 0px; */
    /* width: 45%;  */
    /* margin: 2.5%; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
  }
  .package-card-top {
    
    width: 100%; /* You might need to adjust this depending on the container's width */
    margin: 2.5%;
    
    
  }
  
  .package-card:hover {
    transform: translateY(-5px);
  }
  
  .package-image {
    width: 100%;
    height: auto;
    border-radius: 4px;
  }
  
  .package-title {
    margin-top: 16px;
    font-size: 1.5em;
  }
  
  .package-size {
    color: #fff;
    font-size: 1em;
  }
  
  .package-services img {
    width: 24px;
    height: 24px;
    margin: 5px;
    /* You might want to add additional styles for the icons */
  }
  
  .package-description {
    margin-top: 16px;
    color: #fff;
  }
  
  .package-price {
    margin-top: 16px;
    font-weight: bold;
    font-size: 1.2em;
    color: #27ae60; /* Change color to suit your theme */
  }
  