/* landingPage.css */

.landing-header {
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    /* background-color: #f7f7f7; */
}

.landing-header h1 {
    font-weight: 700;
    /* color: #333; */
}

.landing-header p {
    font-weight: 400;
    /* color: #777; */
    font-size: 1rem;
}

.graphdiv{
    max-width: 30rem;
}
.graph-center{
    text-align: center;
    text-align: -webkit-center;
}
.graph-center.subtitle{
    font-size: 14px;
}
ul li{
    list-style: disc;
}
.btnesq:hover, .btndir:hover, .contact:hover{
    background-color: darkgray !important;
}
.w-3-4{
    width: 75% ;

}
.footer .btn:hover{
    background-color: darkgray !important;
    transform: scale(1.1);
    transition: 0.3s ease-in-out !important;
}
.footer .btn{
    color: black;
    background-color: lightgray ;
}
.text-center-1{
    text-align: -webkit-center !Important;
}
hr{
    border-bottom: 1px solid white ;
    width: 50%;
    text-align: center;
    margin-bottom: 20px !important;
    margin: 40px auto 20px auto !important
}
 
.btnesq, .btndir{
    background-color: white;
    color: black;
    border: 2px solid darkgrey;
    padding: 2px 10px;
    min-width: 90px;
}
.packages-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* Add padding, margins, etc., as needed */
  }
a.contact{
    background-color: white;
    color: black;
    border: 2px solid darkgrey;
    padding: 5px 10px;
    min-width: 90px;
}
.instagram-link {
    display: inline-block;
    color: #000; /* Instagram's official color is black, but they often use gradient */
    text-decoration: none;
    font-size: 1em;
    padding: 8px;
    border-radius: 4px;
    transition: color 0.3s ease;
  }
  
  .instagram-link i {
    margin-right: 8px;
  }
  
  .instagram-link:hover {
    color: #E1306C; /* Slightly darker Instagram color for hover state */
  }
  
.selling-points {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
}
.width-max{
    width: 100% !important;
}

.selling-points h4 {
    font-weight: 700;
    margin-bottom: 1rem;
}

.selling-points p {
    /* color: #666; */
    font-weight: 400;
    font-size: 1rem;
}

.container.gallery-section {
    margin-bottom: 4rem;
}

.footer {
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    /* background-color: #333; */
}

.footer h4 {
    color: #fff;
    font-weight: 700;
    margin-bottom: 1rem;
}

.footer button {
    background-color: #FF5722;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    transition: background-color 0.3s ease;
}

.footer button:hover {
    background-color: #E64A19;
}
.div_photos {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}
.border_important{
    border: 1px solid #fff;
    width: fit-content;
    padding: 10px;
}
.nosidepad.right{
    padding-right: 0px !important;
}
.nosidepad.left{
    padding-left: 0px !important;
}

.boder_important_div{
    text-align: center;
    text-align: -webkit-center;
}
.div_photos{
    background-color: white;
    padding: 10px;
}
.div_photos img{
    padding: 10px 
}
.div_photos h2{
    color: black;
}
.subtitle{
    font-size:12px !important;
}
/* .wrongphoto{
    border: 10px solid red;
}
.rightphoto{
    border: 10px solid green;
} */

/* Gallery Hover Effects */
.imgs_gallery img:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;
}
.mobile_info{
    display: none;
}
@media screen and (max-width: 640px) { 
    .title_mobile{
        font-size: 42px !important;
    }
    .desktop-info{
        display: none !important;
    }
    .mobile_info{
        display: block !important;
    }
    .w-3-4{
        width: 100% !important;
    }
    .div_photos h2{
        font-size: 14px;
    }
}
.contact{
    background-color: white;
    color: black;
    border: 2px solid darkgrey;
    padding: 2px 10px;
    min-width: 90px;
}

